import * as tf from '@tensorflow/tfjs';
import * as mobilenet from '@tensorflow-models/mobilenet';

const AIReorganize = async ({ gridItems }) => {
  const customItems = gridItems.filter(item => item && item.isCustom && item.type !== 'empty');
  if (customItems.length < 2) {
    return gridItems;
  }

  const model = await mobilenet.load();

  const analyzedItems = await Promise.all(
    customItems.map(async (item) => {
      const img = new Image();
      img.src = item.content;
      await new Promise((resolve) => { img.onload = resolve; });
      const tfImg = tf.browser.fromPixels(img);
      const analysis = await model.classify(tfImg);
      const resized = tf.image.resizeBilinear(tfImg, [224, 224]);
      const avgColor = tf.mean(resized, [0, 1]);
      const brightness = tf.mean(avgColor);
      const colorValues = avgColor.arraySync();
      tfImg.dispose();
      resized.dispose();
      return { 
        ...item, 
        analysis, 
        avgColor: colorValues,
        brightness: brightness.dataSync()[0],
        isWarm: isWarmColor(colorValues)
      };
    })
  );

  const reorganizedItems = reorganizeItems(analyzedItems, gridItems);
  return reorganizedItems;
};

const isWarmColor = (color) => {
  const [r, g, b] = color;
  return r > b;  // Simple warm/cool differentiation
};

const reorganizeItems = (items, allGridItems) => {
  const grid = allGridItems.map(item => item && !item.isCustom ? item : null);
  const emptySlots = grid.reduce((acc, item, index) => item === null ? [...acc, index] : acc, []);
  
  // Sort items by warm/cool alternation
  items.sort((a, b) => {
    if (a.isWarm === b.isWarm) {
      return b.brightness - a.brightness;  // Secondary sort by brightness
    }
    return a.isWarm ? -1 : 1;  // Warm colors first
  });

  // Place items in empty slots, maximizing contrast with neighbors
  let warmTurn = true;
  items.forEach(item => {
    let bestSlot = null;
    let maxScore = -Infinity;

    emptySlots.forEach(slot => {
      const neighbors = getNeighbors(slot, grid);
      let contrastScore = neighbors.reduce((sum, neighbor) => {
        if (neighbor && neighbor.avgColor) {
          return sum + calculateContrast(item, neighbor);
        }
        return sum;
      }, 0);

      // Boost score if it matches the warm/cool alternation
      if (item.isWarm === warmTurn) {
        contrastScore += 1000;
      }

      if (contrastScore > maxScore) {
        maxScore = contrastScore;
        bestSlot = slot;
      }
    });

    if (bestSlot !== null) {
      grid[bestSlot] = item;
      emptySlots.splice(emptySlots.indexOf(bestSlot), 1);
      warmTurn = !warmTurn;  // Alternate warm/cool
    }
  });

  // Move remaining empty slots to the beginning
  const finalGrid = grid.filter(item => item !== null);
  while (finalGrid.length < 12) {
    finalGrid.unshift(null);
  }

  return finalGrid;
};

const getNeighbors = (index, grid) => {
  const neighbors = [];
  const row = Math.floor(index / 3);
  const col = index % 3;

  if (row > 0) neighbors.push(grid[index - 3]); // top
  if (row < 3) neighbors.push(grid[index + 3]); // bottom
  if (col > 0) neighbors.push(grid[index - 1]); // left
  if (col < 2) neighbors.push(grid[index + 1]); // right

  return neighbors.filter(n => n !== null);
};

const calculateContrast = (item1, item2) => {
  const colorDiff = item1.avgColor.map((c, i) => Math.abs(c - item2.avgColor[i]));
  const brightnessDiff = Math.abs(item1.brightness - item2.brightness);
  let contrastScore = colorDiff.reduce((a, b) => a + b, 0) + brightnessDiff;

  // Boost contrast score for complementary colors
  if (isComplementary(item1.avgColor, item2.avgColor)) {
    contrastScore *= 1.5;
  }

  return contrastScore;
};

const isComplementary = (color1, color2) => {
  const [r1, g1, b1] = color1;
  const [r2, g2, b2] = color2;
  const threshold = 100;  // Adjust this value to fine-tune complementary detection

  return Math.abs(r1 - r2) > threshold && 
         Math.abs(g1 - g2) > threshold && 
         Math.abs(b1 - b2) > threshold;
};

export default AIReorganize;