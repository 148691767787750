import React, { useCallback, useEffect, useRef } from 'react';

const LogoutConfirmation = ({ onConfirm, onCancel, isDarkMode }) => {
  const dialogRef = useRef(null);

  const handleOutsideClick = useCallback((event) => {
    if (dialogRef.current && !dialogRef.current.contains(event.target)) {
      onCancel();
    }
  }, [onCancel]);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [handleOutsideClick]);

  const overlayClass = `fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50`;
  const dialogClass = `bg-white bg-opacity-90 p-6 rounded-lg shadow-xl max-w-sm w-full mx-4 text-center`;
  const buttonClass = `px-4 py-2 rounded-md text-white font-medium`;

  return (
    <div className={overlayClass}>
      <div ref={dialogRef} className={dialogClass}>
        <h2 className={`text-xl font-bold mb-4 ${isDarkMode ? 'text-gray-800' : 'text-black'}`}>Confirm Logout</h2>
        <p className={`mb-6 ${isDarkMode ? 'text-gray-700' : 'text-gray-600'}`}>Are you sure you want to log out of your Instagram account?</p>
<div className="flex justify-center space-x-4">
  <button 
    onClick={onConfirm}
    className={`${buttonClass} bg-red-500 hover:bg-red-600`}
  >
    Yes
  </button>
  <button 
    onClick={onCancel}
    className={`${buttonClass} bg-gray-500 hover:bg-gray-600`}
  >
    No
  </button>
</div>
      </div>
    </div>
  );
};

export default LogoutConfirmation;