import React, { useRef, useState } from 'react';
import { ReactComponent as TrashIcon } from '../src/assets/icons/trash.svg';

const GridItem = ({ content, onFileUpload, isDarkMode, index, onDragStart, onDragOver, onDrop, onDelete }) => {
  const fileInputRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = () => {
    if (!content || content.type === 'empty' || content.isCustom) {
      fileInputRef.current.click();
    }
  };

  const handleDragStart = (e) => {
    if (content && content.isCustom) {
      e.dataTransfer.setData('text/plain', index.toString());
      onDragStart(index);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy';
  };

  const handleDrop = (e) => {
    e.preventDefault();
    
    if (content && !content.isCustom) {
      // Prevent dropping on fetched Instagram images
      return;
    }
    
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      if (file.type.startsWith('image/') || file.type.startsWith('video/')) {
        onFileUpload(index, file);
      }
    } else {
      const sourceIndex = parseInt(e.dataTransfer.getData('text/plain'), 10);
      onDrop(sourceIndex, index);
    }
  };

  const renderContent = () => {
    if (!content || content.type === 'empty') {
      return (
        <span 
          className={`text-5xl ${
            isDarkMode ? 'text-[#3a3a3a]' : 'text-gray-300'
          } transition-transform duration-300 ease-in-out ${isHovered ? 'rotate-90' : ''}`}
        >
          +
        </span>
      );
    }
    if (content.type === 'error') {
      return (
        <div className="text-red-500 text-xs p-2 text-center">
          <p>{content.content}</p>
          <p className="mt-1">{content.errorDetails}</p>
        </div>
      );
    }
    return (
      <img src={content.content} alt="Content" className="w-full h-full object-cover" />
    );
  };

  const showTrashButton = content && content.isCustom && !(index === 0 && content.type === 'empty');

  const trashIconColor = (!isDarkMode && content && content.type === 'empty') ? 'text-gray-300' : 'text-white';

  return (
    <div
      className={`aspect-square flex items-center justify-center cursor-pointer relative ${
        isDarkMode ? 'bg-[#1a1a1a]' : 'bg-gray-100'
      }`}
      onClick={handleClick}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragStart={handleDragStart}
      draggable={content && content.isCustom}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {renderContent()}
      {showTrashButton && (
        <button 
          className={`absolute top-2 right-2 p-1 transition-opacity duration-300 ease-in-out ${
            isHovered ? 'opacity-80' : 'opacity-0'
          } hover:opacity-80`}
          onClick={(e) => {
            e.stopPropagation();
            onDelete(index);
          }}
        >
          <TrashIcon className={`w-6 h-6 ${trashIconColor}`} />
        </button>
      )}
      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        onChange={(e) => onFileUpload(index, e.target.files[0])}
        accept="image/*,video/*"
      />
    </div>
  );
};

export default GridItem;