import React, { useState, useCallback, useEffect, useMemo } from 'react';
import GridItem from './GridItem';
import MenuButtons from './MenuButtons';
import BottomMenuButtons from './BottomMenuButtons';
import AIReorganize from './AIReorganize';
import LogoutConfirmation from './LogoutConfirmation';
import AIFeedbackPopup from './AIFeedbackPopup';
import { ReactComponent as LogoIcon } from '../src/assets/icons/instagram.svg';

const API_TOKEN = 'IGQWRQYVBfSk1OU0JIMzBCTE93ckUyOUxRVERSdzlMdnpnaWhCY3FncmV1eVlzVjQzNU9EaVBxU2VSdF9nYy14YU5ZAbERXdzVRMDhVMjRGdU5uYkp4Q2JUTy1jWFVpSlJuN3BaMG01QWZAQcG9RbHNuVUR3WWVybkEZD';

const App = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [gridItems, setGridItems] = useState(Array(12).fill({ type: 'empty', isCustom: true }));
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [undoHistory, setUndoHistory] = useState([]);
  const [redoHistory, setRedoHistory] = useState([]);
  const [profileInfo, setProfileInfo] = useState(null);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [showAIFeedback, setShowAIFeedback] = useState(false);
  const [fetchedInstagramImages, setFetchedInstagramImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    setGridItems(Array(12).fill({ type: 'empty', isCustom: true }));
  }, []);

  useEffect(() => {
    const preventDefault = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDrop = (e) => {
      preventDefault(e);
      // Optional: You can add a visual or audio cue here to indicate that
      // dropping outside the grid is not allowed
    };

    document.addEventListener('dragenter', preventDefault);
    document.addEventListener('dragover', preventDefault);
    document.addEventListener('dragleave', preventDefault);
    document.addEventListener('drop', handleDrop);

    return () => {
      document.removeEventListener('dragenter', preventDefault);
      document.removeEventListener('dragover', preventDefault);
      document.removeEventListener('dragleave', preventDefault);
      document.removeEventListener('drop', handleDrop);
    };
  }, []);

  const hasEnoughCustomImages = useMemo(() => {
    return gridItems.filter(item => item && item.isCustom && item.type !== 'empty').length >= 2;
  }, [gridItems]);

  const updateGridItemsWithHistory = useCallback((newGridItems, newImagesLoaded = imagesLoaded) => {
    setUndoHistory(prevHistory => [...prevHistory, { gridItems, imagesLoaded }]);
    setGridItems(newGridItems);
    setImagesLoaded(newImagesLoaded);
    setRedoHistory([]);
  }, [gridItems, imagesLoaded]);

  const handleUndo = useCallback(() => {
    if (undoHistory.length > 0) {
      const previousState = undoHistory[undoHistory.length - 1];
      setRedoHistory(prevRedoHistory => [{ gridItems, imagesLoaded }, ...prevRedoHistory]);
      setGridItems(previousState.gridItems);
      setImagesLoaded(previousState.imagesLoaded);
      setUndoHistory(prevHistory => prevHistory.slice(0, -1));
    }
  }, [undoHistory, gridItems, imagesLoaded]);

  const handleRedo = useCallback(() => {
    if (redoHistory.length > 0) {
      const nextState = redoHistory[0];
      setUndoHistory(prevUndoHistory => [...prevUndoHistory, { gridItems, imagesLoaded }]);
      setGridItems(nextState.gridItems);
      setImagesLoaded(nextState.imagesLoaded);
      setRedoHistory(prevRedoHistory => prevRedoHistory.slice(1));
    }
  }, [redoHistory, gridItems, imagesLoaded]);

  const updateGridItem = useCallback((index, item) => {
    updateGridItemsWithHistory(prev => {
      const newItems = [...prev];
      newItems[index] = item || { type: 'empty', isCustom: true };
      return newItems;
    });
  }, [updateGridItemsWithHistory]);

  const handleDragStart = useCallback((index) => {
    // You can add any necessary logic here
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
  }, []);

  const handleDrop = useCallback((sourceIndex, targetIndex) => {
    if (sourceIndex !== targetIndex) {
      updateGridItemsWithHistory(prev => {
        const newItems = [...prev];
        const sourceItem = newItems[sourceIndex];
        const targetItem = newItems[targetIndex];
        
        // Only allow moving if source is custom and target is custom or empty
        if (sourceItem && sourceItem.isCustom && 
            (!targetItem || targetItem.isCustom || targetItem.type === 'empty')) {
          newItems[targetIndex] = sourceItem;
          newItems[sourceIndex] = targetItem || { type: 'empty', isCustom: true };
        }
        
        return newItems;
      });
    }
  }, [updateGridItemsWithHistory]);
  
  const handleFileUpload = useCallback((index, fileOrEvent) => {
    let file;
    if (fileOrEvent instanceof File) {
      file = fileOrEvent;
    } else if (fileOrEvent.target && fileOrEvent.target.files) {
      file = fileOrEvent.target.files[0];
    } else {
      console.error('Invalid file input');
      return;
    }

    if (file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        updateGridItem(index, { type: 'image', content: e.target.result, isCustom: true });
      };
      reader.readAsDataURL(file);
    } else if (file.type.startsWith('video/')) {
      const videoUrl = URL.createObjectURL(file) + '#t=0.1';
      const video = document.createElement('video');
      video.preload = 'metadata';
      video.muted = true;
      video.playsInline = true;

      video.onloadedmetadata = () => {
        video.currentTime = 0.2;
        video.onseeked = () => {
          const canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          
          canvas.toBlob((blob) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              updateGridItem(index, { 
                type: 'video', 
                content: reader.result,
                videoFile: file, 
                videoUrl: videoUrl,
                isCustom: true 
              });
            };
            reader.onerror = (readerError) => {
              console.error('FileReader error:', readerError);
              updateGridItem(index, { 
                type: 'error', 
                content: 'Error processing video', 
                errorDetails: `FileReader error: ${readerError.message}`,
                isCustom: true 
              });
            };
            reader.readAsDataURL(blob);
          }, 'image/jpeg', 0.95);
        };
      };

      video.onerror = (e) => {
        console.error('Video error:', e, video.error);
        updateGridItem(index, { 
          type: 'error', 
          content: 'Error loading video', 
          errorDetails: `Video error: ${video.error ? video.error.message : 'Unknown error'}. File type: ${file.type}`,
          isCustom: true 
        });
      };

      video.src = videoUrl;
    } else {
      updateGridItem(index, { 
        type: 'error', 
        content: 'Unsupported file type', 
        errorDetails: `File type: ${file.type}`,
        isCustom: true 
      });
    }
  }, [updateGridItem]);

  const addNewBox = useCallback(() => {
    updateGridItemsWithHistory(prev => {
      const newBox = { type: 'empty', isCustom: true };
      return [newBox, ...prev.slice(0, 11)];
    });
  }, [updateGridItemsWithHistory]);

  const fetchImages = useCallback(async () => {
    console.log('Fetching images...');
    try {
      // Fetch user profile information
      console.log('Fetching profile data...');
      const profileResponse = await fetch(`https://graph.instagram.com/me?fields=id,username,account_type,media_count&access_token=${API_TOKEN}`);
      const profileData = await profileResponse.json();
      console.log('Profile data:', profileData);

      if (profileData.error) {
        throw new Error(profileData.error.message);
      }

      setProfileInfo(profileData);

      // Fetch media
      console.log('Fetching media data...');
      const mediaResponse = await fetch(`https://graph.instagram.com/me/media?fields=id,media_type,media_url,thumbnail_url,permalink&access_token=${API_TOKEN}`);
      const mediaData = await mediaResponse.json();
      console.log('Media data:', mediaData);

      if (mediaData.error) {
        throw new Error(mediaData.error.message);
      }

      const images = mediaData.data
        .filter(item => 
          (item.media_type === 'IMAGE' || item.media_type === 'CAROUSEL_ALBUM') ||
          (item.media_type === 'VIDEO' && !item.permalink.includes('/reel/'))
        )
        .map(item => ({
          type: item.media_type.toLowerCase(),
          content: item.media_type === 'VIDEO' ? item.thumbnail_url : item.media_url,
          isCustom: false
        }));

      setFetchedInstagramImages(images);

      return images;
    } catch (error) {
      console.error('Error fetching Instagram data:', error);
      return [];
    }
  }, []);

const initialLoadFeed = useCallback(async () => {
  const images = await fetchImages();
  
  updateGridItemsWithHistory(prev => {
    // Filter out custom uploads (images or videos)
    const customUploads = prev.filter(item => item.isCustom && item.type !== 'empty');
    
    if (customUploads.length === 0) {
      // If there are no custom uploads, leave the first cell empty and fill 2-12 with Instagram images
      return [{ type: 'empty', isCustom: true }, ...images.slice(0, 11)];
    } else {
      // Create a new array with custom uploads at the beginning
      const newGridItems = [...customUploads];
      
      // Fill the remaining slots with Instagram images
      const remainingSlots = 12 - customUploads.length;
      images.slice(0, remainingSlots).forEach(image => {
        newGridItems.push(image);
      });
      
      return newGridItems;
    }
  }, true);
  
  setImagesLoaded(true);
}, [fetchImages, updateGridItemsWithHistory]);

const refreshFeed = useCallback(async () => {
  const newImages = await fetchImages();
  
  updateGridItemsWithHistory(prev => {
    // Filter out custom uploads (images or videos)
    const customUploads = prev.filter(item => item.isCustom && item.type !== 'empty');
    
    if (customUploads.length === 0) {
      // If there are no custom uploads, leave the first cell empty and fill 2-12 with Instagram images
      return [{ type: 'empty', isCustom: true }, ...newImages.slice(0, 11)];
    } else {
      // Create a new array with custom uploads at the beginning
      const updatedItems = [...customUploads];
      
      // Fill the remaining slots with new Instagram images
      const remainingSlots = 12 - customUploads.length;
      newImages.slice(0, remainingSlots).forEach(image => {
        updatedItems.push(image);
      });
      
      return updatedItems;
    }
  });

  setImagesLoaded(true);
}, [fetchImages, updateGridItemsWithHistory]);
  
  const loadOrRefreshFeed = useCallback(async () => {
    if (imagesLoaded) {
      setIsRefreshing(true);
    } else {
      setIsLoading(true);
    }
    try {
      if (imagesLoaded) {
        await refreshFeed();
      } else {
        await initialLoadFeed();
      }
    } finally {
      setTimeout(() => {
        setIsRefreshing(false);
        setIsLoading(false);
      }, 1000); // Ensure "LOADING..." or "REFRESHING..." is visible for at least 1 second
    }
  }, [imagesLoaded, refreshFeed, initialLoadFeed]);
  
  const handleDelete = useCallback((index) => {
    updateGridItemsWithHistory(prev => {
      const newItems = [...prev];
      if (index === 0 && newItems[index].type === 'empty') {
        // Don't allow deletion of the first cell if it's empty
        return newItems;
      }
      if (newItems[index].type === 'empty' && newItems[index].isCustom) {
        // Remove the empty cell and shift others
        newItems.splice(index, 1);
        // Add a new Instagram image at the end if available
        const nextInstagramImage = fetchedInstagramImages[newItems.filter(item => !item.isCustom).length];
        if (nextInstagramImage) {
          newItems.push(nextInstagramImage);
        } else {
          newItems.push({ type: 'empty', isCustom: true });
        }
      } else {
        // Just empty the cell
        newItems[index] = { type: 'empty', isCustom: true };
      }
      return newItems;
    });
  }, [updateGridItemsWithHistory, fetchedInstagramImages]);

const handleAIReorganize = useCallback(async () => {
  if (!hasEnoughCustomImages) {
    return;
  }
  setIsProcessing(true);
  try {
    const reorganizedItems = await AIReorganize({ gridItems });
    const hasChanges = JSON.stringify(reorganizedItems) !== JSON.stringify(gridItems);

    if (!hasChanges) {
      setShowAIFeedback(true);
    } else {
      const updatedItems = reorganizedItems.map(item => {
        // Add this null check
        if (item && item.isCustom) {
          return { ...item, isCustom: true };
        }
        // If item is null or undefined, return an empty object or handle it as needed
        return item || { type: 'empty', isCustom: false };
      });
      updateGridItemsWithHistory(updatedItems);
    }
  } catch (error) {
    console.error('Error during AI reorganization:', error);
  } finally {
    setIsProcessing(false);
  }
}, [gridItems, hasEnoughCustomImages, updateGridItemsWithHistory]);

  const closeAIFeedback = useCallback(() => {
    setShowAIFeedback(false);
  }, []);

  const toggleTheme = useCallback(() => setIsDarkMode(prev => !prev), []);

  const handleLogout = useCallback(() => {
    setShowLogoutConfirmation(true);
  }, []);

  const confirmLogout = useCallback(() => {
    setProfileInfo(null);
    setGridItems(Array(12).fill({ type: 'empty', isCustom: true }));
    setImagesLoaded(false);
    setShowLogoutConfirmation(false);
  }, []);

  const cancelLogout = useCallback(() => {
    setShowLogoutConfirmation(false);
  }, []);

  return (
    <div 
      className={`min-h-screen ${isDarkMode ? 'bg-black text-white' : 'bg-white text-black'}`}
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => e.preventDefault()}
    >
      <div className="container mx-auto p-3 max-w-2xl">
        <div className="flex flex-col items-center mb-6">
          <LogoIcon className={`w-12 h-12 mb-2 mt-2 ${isDarkMode ? 'text-white fill-white' : 'text-white fill-white'}`} />
          <span className="text-lg font-semibold">
            {profileInfo ? `@${profileInfo.username}` : '@nextpostdemo'}
          </span>
        </div>
    <MenuButtons 
      isDarkMode={isDarkMode} 
      imagesLoaded={imagesLoaded}
      loadOrRefreshFeed={loadOrRefreshFeed}
      addNewBox={addNewBox}
      handleAIReorganize={handleAIReorganize}
      isProcessing={isProcessing}
      isLoading={isLoading}
      isRefreshing={isRefreshing}
      hasEnoughCustomImages={hasEnoughCustomImages}
    />
            <div className="grid grid-cols-3 gap-0.5 my-0">
          {gridItems.map((item, index) => (
<GridItem
  key={index}
  index={index}
  content={item}
  onFileUpload={handleFileUpload}
  isDarkMode={isDarkMode}
  onDragStart={handleDragStart}
  onDragOver={handleDragOver}
  onDrop={handleDrop}
  onDelete={handleDelete}
/>
          ))}
        </div>
        <BottomMenuButtons 
          isDarkMode={isDarkMode} 
          toggleTheme={toggleTheme} 
          handleUndo={handleUndo}
          handleRedo={handleRedo}
          canUndo={undoHistory.length > 0}
          canRedo={redoHistory.length > 0}
          handleLogout={handleLogout}
        />
   {showLogoutConfirmation && (
          <LogoutConfirmation 
            onConfirm={confirmLogout} 
            onCancel={cancelLogout} 
            isDarkMode={isDarkMode}
          />
        )}
        {showAIFeedback && (
          <AIFeedbackPopup 
            onClose={closeAIFeedback}
            isDarkMode={isDarkMode}
          />
        )}
      </div>
    </div>
  );
};

export default App;