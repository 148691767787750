import React, { useState } from 'react';
import Lottie from 'lottie-react';
import { ReactComponent as RefreshFeedIcon } from '../src/assets/icons/grid (2).svg';
import { ReactComponent as AddCellIcon } from '../src/assets/icons/iconmonstr-plus-2.svg';
import { ReactComponent as AIReorderIcon } from '../src/assets/icons/magic-wand.svg';
import syncAnimation from '../src/assets/icons/sync.json';

const Icon = ({ component: Component, isDarkMode }) => (
  <Component className={`w-4 h-4 mr-2 ${isDarkMode ? 'fill-[#A8A8A8] group-hover:fill-white' : 'fill-[#737373] group-hover:fill-black'}`} />
);

const AnimatedIcon = ({ isDarkMode }) => (
  <Lottie 
    animationData={syncAnimation} 
    loop={true}
    autoplay={true}
    style={{ width: '1rem', height: '1rem', marginRight: '0.5rem' }}
    rendererSettings={{ 
      preserveAspectRatio: 'xMidYMid slice',
      className: isDarkMode ? 'fill-[#A8A8A8]' : 'fill-[#737373]'
    }}
  />
);

const MenuButtons = ({ isDarkMode, imagesLoaded, loadOrRefreshFeed, addNewBox, handleAIReorganize, isProcessing, isLoading, isRefreshing, hasEnoughCustomImages }) => {
  const [aiButtonText, setAiButtonText] = useState('AI REORDER');

  const buttonClass = `
    flex-1 py-5 text-sm font-medium flex items-center justify-center group
    ${isDarkMode ? 'text-[#A8A8A8] hover:text-white' : 'text-[#737373] hover:text-black'}
    border-t-2 border-transparent ${isDarkMode ? 'hover:border-white' : 'hover:border-black'}
    transition-all duration-200 ease-in-out
  `;

  const handleAIReorderClick = () => {
    if (hasEnoughCustomImages) {
      handleAIReorganize();
    } else {
      setAiButtonText('ADD MORE');
      setTimeout(() => setAiButtonText('AI REORDER'), 1000);
    }
  };

  return (
    <div className={`flex border-t ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
      <button
        className={buttonClass}
        onClick={loadOrRefreshFeed}
        disabled={isLoading || isRefreshing}
      >
        {isLoading || isRefreshing ? (
          <>
            <AnimatedIcon isDarkMode={isDarkMode} />
            {isLoading ? 'LOADING...' : 'REFRESHING...'}
          </>
        ) : (
          <>
            <Icon component={RefreshFeedIcon} isDarkMode={isDarkMode} />
            {imagesLoaded ? 'REFRESH' : 'LOAD FEED'}
          </>
        )}
      </button>
      <button
        className={buttonClass}
        onClick={addNewBox}
      >
        <Icon component={AddCellIcon} isDarkMode={isDarkMode} />
        ADD CELL
      </button>
      <button
        className={buttonClass}
        onClick={handleAIReorderClick}
        disabled={isProcessing}
      >
        {isProcessing ? (
          <>
            <AnimatedIcon isDarkMode={isDarkMode} />
            PROCESSING...
          </>
        ) : (
          <>
            <Icon component={AIReorderIcon} isDarkMode={isDarkMode} />
            {aiButtonText}
          </>
        )}
      </button>
    </div>
  );
};

export default MenuButtons;